import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="p-8 max-w-3xl mx-auto">
      <Link to="/" className="px-4 py-2 text-white bg-neutral-800 bg-opacity-70 hover:bg-opacity-100 rounded shadow">
          Back home
      </Link>
      <h1 className="text-3xl font-bold  mt-6 mb-6">Privacy Policy</h1>
      <p className="text-lg mb-4"><strong>Last Updated:</strong> 20th Nov 2024</p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Information We Collect</h2>
        <p>We do not collect any personal information from visitors to our website. We do not collect, store, or share any data such as names, email addresses, location, or other personal information.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Cookies</h2>
        <p>Our website does not use cookies or any other tracking technologies.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Third-Party Services</h2>
        <p>We do not use any third-party services, such as analytics or advertising tools, that collect or track your data.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Security</h2>
        <p>Since we do not collect any personal data, there is no personal information to protect. However, we take reasonable measures to ensure the overall security and integrity of our website.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. If we make significant changes, we will post the updated policy on this page and update the "Last Updated" date at the top.</p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p><strong>Email:</strong> newideaoftheday@gmail.com</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;

