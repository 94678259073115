import { useState, useEffect } from 'react';

const DailySuggestion = () => {
  const [activity, setActivity] = useState('');

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch('/activities.txt');
        const text = await response.text();
        const activities = text.split('\n').filter(line => line.trim() !== '');

        const startDate = new Date('2024-11-17'); // Adjust as needed
        const today = new Date();
        const diffInDays = Math.floor((today - startDate) / (1000 * 60 * 60 * 24));

        const activityForToday = activities[diffInDays % activities.length];
        setActivity(activityForToday);
      } catch (error) {
        console.error('Error loading activities:', error);
      }
    };

    fetchActivities();
  }, []);

  return activity; // Return the activity
};

export default DailySuggestion;
