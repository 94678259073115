import { Link } from 'react-router-dom';
import React, { useState } from "react";
import DailySuggestion from './DailySuggestion';

const Mainpage = () => {

    // State to manage the visibility of the suggestion
    const [isTextVisible, setIsTextVisible] = useState(false);

    // Toggle visibility function
    const toggleTextVisibility = () => {
        setIsTextVisible(prevState => !prevState);
    };

    const title = "Try something new today"
    const introductory_text = "Everyday we generate a different idea to keep your days interesting and keep you exploring new experiences! \n\nSuggestions can be anything from a new game, song or even exploring a new place! "

    const suggestion_title = "Idea suggestion for today: "
    const suggestion_of_the_day = DailySuggestion();
    // const suggestion_of_the_day = "Build something out of LEGO or blocks."
    // const suggestion_of_the_day = "Explore a New Cuisine"
    // const suggestion_of_the_day = "Write a Letter or Email to Someone you like and tell them about your favourite event today"

    return ( 
        <div className="flex flex-col md:flex-row items-center">
            <div className='md:w-1/2 p-10 whitespace-pre-line'>
                <h1 className='font-extrabold text-3xl lg:text-5xl tracking-tight md:-mb-2 w-full p-4 rounded border-transparent border-9 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500'>{title}</h1>
                <div className='text-3xl lg:text-4xl md:-mb-2 p-4'>
                    {introductory_text}
                </div>
            </div>
            <div className='flex flex-col md:w-1/2 items-center'>
                <div className="font-semibold text-3xl md:text-4xl p-2 md:mt-20">
                    <p className='bg-gradient-to-r from-purple-900 to-blue-800 text-transparent bg-clip-text p-1'>{suggestion_title}</p>
                </div>
                <button
                    onClick={toggleTextVisibility}
                    className="px-4 py-2 my-4 border-2 border-white bg-blue-800 bg-opacity-80 text-white font-semibold rounded-lg shadow hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-white">
                    {isTextVisible ? 'Hide Suggestion' : 'Reveal Suggestion'}
                </button>
                <div className={`bg-blue-900 bg-opacity-95 rounded-lg border border-gray-500 mt-auto mb-20 max-w-2xl h-64 w-full mx-auto p-4 flex items-center justify-center transition-all duration-300`}
                    style={{
                        minHeight: "150px", // Ensure the box has a minimum height
                    }}
                    >
                    {isTextVisible ? (
                        <p
                        className="md:p-10 font-bold bg-gradient-to-r from-slate-200 to-white text-transparent bg-clip-text text-center"
                        style={{
                            fontSize: `clamp(1rem, ${Math.max(
                            5 - suggestion_of_the_day.length / 30,
                            1.5
                            )}rem, 2rem)`, // Dynamically scale font size
                            lineHeight: '1.2',
                            overflowWrap: 'break-word', // Ensure long words break properly
                            wordBreak: 'break-word', // Handle overflow
                            whiteSpace: 'normal', // Allow multi-line text
                        }}
                        >
                        {suggestion_of_the_day}
                        </p>
                    ) : (
                        <p className="text-transparent text-center"> </p> // Empty space when hidden
                    )}
                    </div>
            </div>
            <footer className="fixed bottom-0 left-0 w-full flex justify-center space-x-6 p-4 bg-indigo-500 text-white font-semibold">
                <Link to="/terms" className="text-base hover:underline">
                    Terms of Service
                </Link>
                <Link to="/privacy" className="text-base hover:underline">
                    Privacy Policy
                </Link>
            </footer>
        </div>
     );
}
 
export default Mainpage;