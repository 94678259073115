import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="p-8 max-w-3xl mx-auto">
      <Link to="/" className="px-4 py-2 text-white bg-neutral-800 bg-opacity-70 hover:bg-opacity-100 rounded shadow">
        Back home
      </Link>
      <h1 className="text-3xl font-bold mb-6 mt-6">Terms of Service</h1>
      <p className="text-lg mb-4"><strong>Last Updated:</strong> 20th Nov 2024</p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Use of the Website</h2>
        <p>
          By accessing or using New Idea of the Day, you agree to comply with these Terms of Service and all applicable laws and regulations.
          If you do not agree with these terms, please do not use the website.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. Content Disclaimer</h2>
        <p>
          The content of the daily suggestions is generated for entertainment purposes only. New Idea of the Day is not responsible for any actions
          taken by users based on the suggestions. Use them at your own discretion.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. Website Availability</h2>
        <p>
          We strive to maintain the website's availability and functionality. However, we do not guarantee that the website will always be available
          or free of errors. We reserve the right to modify or discontinue the website without notice.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. Modifications to the Terms of Service</h2>
        <p>
          We may update these Terms of Service from time to time. When we do, we will post the updated terms on this page and update the "Last Updated"
          date at the top. It is your responsibility to review these terms periodically.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">5. Limitation of Liability</h2>
        <p>
          New Idea of the Day is not liable for any damages, losses, or expenses arising from the use of the website or the suggestions provided. 
          This includes, but is not limited to, indirect, incidental, or consequential damages.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. Dispute Resolution</h2>
        <p>
        In the event of any dispute, controversy, or claim arising out of or relating to the use of New Idea of the Day or these Terms of Service,
    you agree to attempt to resolve the matter amicably by contacting us by email down below. However, we are not willing or obliged to
    participate in any dispute resolution proceedings in front of a consumer arbitration board.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2">7. Contact Information</h2>
        <p>If you have any questions or concerns about these Terms of Service, please contact us at:</p>
        <p><strong>Email:</strong> newideaoftheday@gmail.com</p>
      </section>
    </div>
  );
};

export default TermsOfService;
