import './App.css';
import './index.css'; 
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Mainpage from './Mainpage';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

function App() {

  return ( 
    <div className="min-h-screen bg-background-colour">
      <div className='w-5/6 flex justify-center mx-auto'>
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Mainpage />}/>
          <Route path="/Privacy" element={<PrivacyPolicy />}/>
          <Route path="/terms" element={<TermsOfService />} />
        </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
